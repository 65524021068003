export default function ProductServer(ajax, config) {
  const { domainName, ProductServer } = config
  return {
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    product: {
      // 自由行-常规自由行-产品复制
      copy: (opt) =>
        ajax({
          url: '/pre-packaged-products/copy',
          method: 'POST',
          ...opt,
        }),
      // excel批量导入产品
      upload: `${domainName + ProductServer}/product/import-excel`, // elementUI地址所需
      // 产品批量导入模板下载
      download: (opt) =>
        ajax({
          url: `/product/download-template`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
      // 产品列表
      list: (opt) =>
        ajax({
          url: '/products',
          method: 'get',
          ...opt,
        }),
      // 产品详情-外系统统一调用产品中心
      detail: (opt) =>
        ajax({
          url: '/products/center/detail',
          method: 'get',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/product/add',
          method: 'post',
          ...opt,
        }),
      // 修改产品
      update: (opt) =>
        ajax({
          url: '/product/update',
          method: 'post',
          ...opt,
        }),
      // 根据ID查询产品信息
      info: (opt) =>
        ajax({
          url: `/product/${opt.c_productId}`,
          method: 'get',
          ...opt,
        }),
      // 产品操作记录查询
      logs: (opt) =>
        ajax({
          url: `/product/logs`,
          method: 'get',
          ...opt,
        }),
      // 单个上下架
      upStatus: (opt) =>
        ajax({
          url: `/product/update-status`,
          method: 'post',
          ...opt,
        }),
      // 批量上下架
      batchUpStatus: (opt) =>
        ajax({
          url: `/product/batch-update-status`,
          method: 'post',
          ...opt,
        }),
      /**
       * 批量查询 产品基本信息（目前用于查询下拉）
       * @param {*} opt
       * @returns
       */
      basicList: (opt) =>
        ajax({
          url: '/product-basic-list',
          method: 'post',
          ...opt,
        }),
    },
    // 产品日志
    productsLog: (opt) => ajax({ url: `/products/logs`, method: 'get', ...opt }),
    // 动态打包
    dynamicPackaged: {
      // 动态打包产品-状态-更新
      statusUpdata: (opt) =>
        ajax({ url: `/dynamic-packaged-product/status`, method: 'PATCH', ...opt }),
      list: (opt) => ajax({ url: `/dynamic-packaged-products`, method: 'get', ...opt }),
      add: (opt) => ajax({ url: `/dynamic-packaged-products`, method: 'post', ...opt }),
      update: (opt) => ajax({ url: `/dynamic-packaged-products`, method: 'PATCH', ...opt }),
      info: (opt) => ajax({ url: `/dynamic-packaged-products/${opt.cId}`, method: 'get', ...opt }),
      // excel批量导入产品
      upload: `${domainName + ProductServer}/products/import-excel`,
      // 产品批量导入模板下载
      download: (opt) =>
        ajax({
          url: `/products/download-template`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 预打包，常规打包
    pre: {
      // 获取页面全量配置表
      getConfig: (opt) =>
        ajax({ url: `/product-field-config/field-config`, method: 'get', ...opt }),
      // 枚举模板数据
      temEnums: (opt) => ajax({ url: `/template`, method: 'get', ...opt }),
      // 根据品类获得团类型
      getTeamType: (opt) => ajax({ url: `/team/type/by-product-class`, method: 'get', ...opt }),
      // 单项委托-依据产品类目获取接口
      getEntrustType: (opt) =>
        ajax({ url: `/team/entrust-type/by-product-class`, method: 'get', ...opt }),
      list: (opt) => ajax({ url: `/pre-packaged-products`, method: 'get', ...opt }),
      // 基础信息-新增
      allInfoAdd: (opt) => ajax({ url: `/pre-packaged-products`, method: 'POST', ...opt }),
      // 基础信息-更新
      baseInfoUpdate: (opt) =>
        ajax({ url: `/pre-packaged-products/${opt.cProductId}`, method: 'PATCH', ...opt }),
      // 状态-更新
      status: (opt) => ajax({ url: `/pre-packaged-products/status`, method: 'PATCH', ...opt }),
      // 交通信息-更新
      traffics: (opt) =>
        ajax({
          url: `/pre-packaged-products/traffics/${opt.cProductId}`,
          method: 'PATCH',
          ...opt,
        }),
      // 提前拼接好的模板数据
      templateInfo: (opt) =>
        ajax({ url: `/structural/detail/${opt.cProductId}`, method: 'get', ...opt }),
      info: (opt) => ajax({ url: `/detail/${opt.cProductId}`, method: 'get', ...opt }),
      recommendUpdate: (opt) => ajax({ url: `/product/recommend`, method: 'post', ...opt }),
      cost: (opt) => ajax({ url: `/resource/cost`, method: 'post', ...opt }),
      instructions: (opt) => ajax({ url: `/resource/instructions`, method: 'post', ...opt }),
      updateSync: (opt) => ajax({ url: `/update-sync`, method: 'post', ...opt }),
      // 所有标签枚举
      allTags: (opt) => ajax({ url: `/products/all-tags`, method: 'get', ...opt }),
      selTags: (opt) => ajax({ url: `/products/selected-tags`, method: 'get', ...opt }),
      editTags: (opt) => ajax({ url: `/products/tags`, method: 'post', ...opt }),
      // 资源打包
      resourcePackaged: {
        list: (opt) =>
          ajax({ url: `/pre-packaged-products/resource-packaged`, method: 'get', ...opt }),
        edit: (opt) =>
          ajax({ url: `/pre-packaged-products/resource-packaged`, method: 'PATCH', ...opt }),
        // 更新价格
        batchEditPrice: (opt) =>
          ajax({ url: `/resource-date-rule/batchUpdate`, method: 'post', ...opt }),
        // 设置加价规则
        resourceDateRuleUpdate: (opt) =>
          ajax({ url: `/resource-date-rule/update`, method: 'post', ...opt }),
      },
      singleGroupProductDetail: (productId) =>
        ajax({ url: `/single-group-product/${productId}`, method: 'get' }),
      resourceRulePack: {
        list: (opt) => ajax({ url: `/product-resource-rule/list`, method: 'POST', ...opt }),
        add: (opt) => ajax({ url: `/product-resource-rule/add`, method: 'POST', ...opt }),
        edit: (opt) => ajax({ url: `/product-resource-rule/edit`, method: 'POST', ...opt }),
        del: (opt) => ajax({ url: `/product-resource-rule/delete`, method: 'POST', ...opt }),
        info: (opt) => ajax({ url: `/product-resource-rule/detail`, method: 'POST', ...opt }),
        // 查询规则下酒店套餐资源列表
        queryHotel: (opt) =>
          ajax({
            url: `/product-resource-rule/hotel-package-resource-list`,
            method: 'POST',
            ...opt,
          }),
        // 查询规则下门券资源列表
        queryTicket: (opt) =>
          ajax({
            url: `/product-resource-rule/ticket-resource-list`,
            method: 'POST',
            ...opt,
          }),
      },
    },
    // 批量获取资源POI信息
    productResourcePoi: (opt) =>
      ajax({ url: `/product-resource-poi/${opt.productId}`, method: 'get', ...opt }),
    // 签证认证
    visa: {
      list: (opt) => ajax({ url: `/visa-auth-product`, method: 'GET', ...opt }),
    },
    // 门票产品
    ticket: {
      list: (opt) => ajax({ url: `/ticket-product`, method: 'get', ...opt }),
      // 门券产品新增或更新资源
      resourceListUp: (opt) =>
        ajax({ url: `/ticket-product/save-and-update-resource`, method: 'POST', ...opt }),
      // 门券产品删除关联
      packResourceDel: (opt) =>
        ajax({ url: `/ticket-product/delete-resource`, method: 'POST', ...opt }),
      // 提交审批
      submitAudit: (opt) => ajax({ url: `/product-approval/submit-audit`, method: 'post', ...opt }),
      // 审批操作
      auditDeal: (opt) => ajax({ url: `/product-approval/audit-deal`, method: 'post', ...opt }),
      // 撤销审核
      auditRevert: (opt) => ajax({ url: `/product-approval/audit-revert`, method: 'post', ...opt }),
    },
    approval: {
      // 提交审批
      batchSubmitAudit: (opt) =>
        ajax({ url: `/product-approval/batch/submit-audit`, method: 'post', ...opt }),
    },
    // 产品系统-预打包产品-产品编码 查询 产品对应的资源列表相关数据
    packageBaseList: (opt) => ajax({ url: `/product-packaged-base-list`, method: 'get', ...opt }),
    // 产品包含的场次
    productSession: (opt) => ajax({ url: `/product-session/query`, method: 'post', ...opt }),
    team: {
      teamTypeTree: (opt) =>
        ajax({ url: `/team/tree-form-type/by-product-class`, method: 'GET', ...opt }),
    },
    hotelSet: {
      list: (opt) => ajax({ url: `/product-hotel-package/page`, method: 'get', ...opt }),
      packAddResource: (opt) =>
        ajax({ url: `/product-hotel-package/save-resource`, method: 'post', ...opt }),
    },
    // 产品包装加价规则
    productAddRule: (opt) =>
      ajax({
        url: `/product-hotel-package/resource-date-rule/default/batchCreate`,
        method: 'put',
        ...opt,
      }),
    // 酒店产品
    hotel: {
      list: (opt) => ajax({ url: `/hotel-products`, method: 'get', ...opt }),
      // 创建加价规则
      createDefaultRule: (opt) =>
        ajax({ url: `/resource-date-rule/default/batchCreate`, method: 'put', ...opt }),
    },
  }
}
