import dayjs from 'dayjs'
// 地址栏参数转成对象参数
import config from '@/store/config'
import { isExternal } from './validate'
import { roundFractional } from './number'

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach((v) => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}
// 深度克隆数组或者对象
export const deepClone = (o) => {
  if (o instanceof Array) {
    let n = []
    for (let i = 0; i < o.length; ++i) {
      n[i] = deepClone(o[i])
    }
    return n
  } else if (o instanceof Object && !(o instanceof Function)) {
    let n = {}
    for (let i in o) {
      n[i] = deepClone(o[i])
    }
    return n
  } else {
    return o
  }
}
//判断两个对象的值是否相等
export const isEqual = (a, b) => {
  const classNameA = toString.call(a)
  const classNameB = toString.call(b)
  // 如果数据类型不相等，则返回false
  if (classNameA !== classNameB) {
    return false
  } else {
    // 如果数据类型相等，再根据不同数据类型分别判断
    if (classNameA === '[object Object]') {
      for (let key in a) {
        if (!isEqual(a[key], b[key])) return false
      }
      for (let key in b) {
        if (!isEqual(a[key], b[key])) return false
      }
      return true
    } else if (classNameA === '[object Array]') {
      if (a.length !== b.length) {
        return false
      } else {
        for (let i = 0, len = a.length; i < len; i++) {
          if (!isEqual(a[i], b[i])) return false
        }
        return true
      }
    } else if (classNameA === '[object Function]') {
      return a.toString() === b.toString()
    } else {
      return Object.is(a, b)
    }
  }
}
// 节流函数
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
// 文件下载
export const fileDownload = (response) => {
  return new Promise((res, rej) => {
    try {
      if (!response) return null
      const fileName = response.headers['content-disposition'].split("filename*=utf-8''")[1]
      const fileNameGroup = fileName.split('.')
      const fileType = fileNameGroup[fileNameGroup.length - 1].toLowerCase()
      const blob = new Blob([response.data], { type: typeDic[fileType] })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = decodeURIComponent(fileName)
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
      res(false)
    } catch (e) {
      // blob转json
      const reader = new FileReader()
      reader.readAsText(response.data)
      reader.onload = (result) => {
        let resData = JSON.parse(result.target.result)
        res(resData)
      }
    }
  })
}
export const UrlFileDownload = async (url, name) => {
  if (!url || !name) return
  try {
    const res = await fetch(url)
    const blob = await res.blob()

    const downloadElement = document.createElement('a')
    const href = URL.createObjectURL(blob)
    downloadElement.href = href
    console.log(href)
    downloadElement.download = name
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)
  } catch (e) {
    console.error(e)
  }
}
//根据文件后缀名来映射Blob Type
export const typeDic = {
  docx: 'application/msword',
  doc: 'application/msword',
  bin: 'application/octet-stream',
  exe: 'application/octet-stream',
  so: 'application/octet-stream',
  dll: 'application/octet-stream',
  pdf: 'application/pdf',
  ai: 'application/postscript',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  dir: 'application/x-director',
  js: 'application/x-javascript',
  swf: 'application/x-shockwave-flash',
  xhtml: 'application/xhtml+xml',
  xht: 'application/xhtml+xml',
  zip: 'application/zip',
  mid: 'audio/midi',
  midi: 'audio/midi',
  mp3: 'audio/mpeg',
  rm: 'audio/x-pn-realaudio',
  rpm: 'audio/x-pn-realaudio-plugin',
  wav: 'audio/x-wav',
  bmp: 'image/bmp',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  css: 'text/css',
  html: 'text/html',
  htm: 'text/html',
  txt: 'text/plain',
  xsl: 'text/xml',
  xml: 'text/xml',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  avi: 'video/x-msvideo',
  movie: 'video/x-sgi-movie',
  mp4: 'video/mp4', //mov,m4v,mp4
  ogv: 'video/ogg', //ogv,opus
}
// 选择文件
export function selectFile({ options = false, accept = [] } = {}) {
  return new Promise((resolve) => {
    let input = document.createElement('input')
    input.type = 'file'
    input.accept = accept.toString()
    options.multiple ? (input.multiple = 'multiple') : ''
    input.click()

    const remove = () => {
      input.removeEventListener('input', watchUpload, false)
      input = null
    }

    const watchUpload = (e) => {
      // 兼容火狐
      const file = e.target.files
      remove()
      resolve(file)
    }
    input.addEventListener('input', watchUpload, false)
  })
}
// 文件基础校验类
export const filesChecks = {
  // 文件数量限制
  len(files, max) {
    const len = files.length
    if (len > max) {
      return false
    } else return true
  },
  // 图片地址和是否本地图片
  async getImgSize(url, isLocal = true) {
    if (!url) return Promise.reject('图片地址不能为空')
    const image = new Image()
    console.log(url, 'url -0-------------------')
    if (isLocal) image.src = await this.getLocalImgUrl(url)
    else image.src = url
    console.log('getImgSize----------------')
    return new Promise((resolve, reject) => {
      image.onload = () => {
        resolve({
          width: image.width,
          height: image.height,
        })
      }
      image.onerror = (imgEvent) => {
        reject(imgEvent)
      }
    })
  },
  // 获取本地图片url地址
  getLocalImgUrl(localFile) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        console.log(event, 'event')
        resolve(event.target.result)
      }
      reader.onerror = (e) => {
        reject(e)
      }
      reader.readAsDataURL(localFile)
    })
  },
  // 图片像素校验,这是一个promise函数
  async imgPx({ files = [], width = 375, height = 375, isLocal = true }) {
    try {
      for (let i = 0; i < files.length; i++) {
        const imgWh = await this.getImgSize(files[i], isLocal)
        if (imgWh.width < width || imgWh.height < height) return Promise.reject(false)
      }
      return Promise.resolve(true)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 文件大小限制,按Mb计算
  size(files, size) {
    for (let i = 0; i < files.length; i++) {
      if (size === 0 || files[i].size / 1024 / 1024 > size) return false
    }
    return true
  },
  // 文件类型限制
  /*
   * img: 'image/jpeg', 'image/png'
   * execl: xls:'application/vnd.ms-excel',xlsx:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
   * */
  type(files, type = ['image/jpeg', 'image/png']) {
    for (let i = 0; i < files.length; i++) {
      if (type.length < 1 || !type.includes(files[i].type)) return false
    }
    return true
  },
}
// 去除所有空格
export const noSpace = (str) => str.replace(/\s/g, '')
// 获取当前哈希路由
export function getHashUrl() {
  const url = location.hash.split('?')[0]
  return url.substring(1, url.length)
}

// 全角转半角
export function ToCDB(str) {
  let tmp = ''
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248)
    } else {
      tmp += String.fromCharCode(str.charCodeAt(i))
    }
  }
  return tmp
}
// 半角转全角
function ToDBC(txtstring) {
  let tmp = ''
  for (let i = 0; i < txtstring.length; i++) {
    if (txtstring.charCodeAt(i) == 32) {
      tmp = tmp + String.fromCharCode(12288)
    } else if (txtstring.charCodeAt(i) < 127) {
      tmp = tmp + String.fromCharCode(txtstring.charCodeAt(i) + 65248)
    }
  }
  return tmp
}
// 是否拼接https图片前缀
export function isSplicingImgPrefix(url) {
  if (!url) return url
  if (isExternal(url)) return url
  else return config.nfsBaseUrl + url
}

// 循环数组算总数，numArr示例：[1,2,"3","a",undefined] => 6
export const reduceAdd = (numArr) => {
  return numArr.reduce((total, item) => {
    if (item === null || item === undefined || item === '' || isNaN(Number(item))) {
      return total
    }
    return roundFractional(+item + total)
  }, 0)
}

// 获取某月的第一天和最后一天， 默认当前月
export const getMonthFirstDayAndLastDay = (date = new Date()) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return [dayjs(firstDay).format('YYYY-MM-DD'), dayjs(lastDay).format('YYYY-MM-DD')]
}
// 得到上传图片的高度
export function getImgInfo(file) {
  return new Promise((resolve) => {
    let img = new Image() //手动创建一个Image对象
    let url = window.URL || window.webkitURL
    img.src = url.createObjectURL(file) //创建Image的对象的url
    img.onload = function () {
      const { height, width } = this
      resolve({ height, width })
    }
  })
}
export function desensiEmail(email) {
  return email.replace(/(.{0,3})(.{0,4})(.*)@(.*)/, '$1****$3@$4')
  // return email.replace(/^(.{0,3})?.*@(.*)/, '$1***@$2')
}
/**
 *处理自动引入
 * @param moduleArr
 * @param  moduleNameCallBack  对象名处理回调
 */
export function handleFiles(moduleArr, moduleNameCallBack) {
  return moduleArr.keys().reduce((modules, modulePath) => {
    const moduleName = moduleNameCallBack(modulePath)
    // console.log(moduleName, 2333333)
    const value = moduleArr(modulePath)
    modules[moduleName] = value.default
    return modules
  }, {})
}

// 数据根据某个字段去重
export const distinct = (arr, key) => {
  let obj = {}
  arr = arr.reduce((preVal, curVal) => {
    obj[curVal[key]] ? '' : (obj[curVal[key]] = preVal.push(curVal))
    return preVal
  }, [])
  return arr
}
